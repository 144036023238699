.spinner-pdf {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin-pdf 2s linear infinite;
  margin-bottom: 20px;
  margin-top: 30px;
}

@keyframes spin-pdf {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Header */
.agnsy-header {
  height: 70px;
  background-color: #000000;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

/* Content */
.agnsy-header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.agnsy-header__logo {
  height: 28px;
  margin-top: 9px;
  margin-left: 10px;
  width: auto;
}

.agnsy-header__text {
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  margin-left: 40px;
  cursor: pointer;
}

.agnsy-header__right {
  display: flex;
  align-items: center;
}

.agnsy-header__my-homes,
.agnsy-header__create-account {
  color: #000000;
  background-color: #ffffff;
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 8px;
  text-decoration: none;
  margin-left: 40px;
  margin-right: 20px;
}

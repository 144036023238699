/* ========== AI OFFER WRITER STYLES ========== */
.ai-offer-writer {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  overflow-y: auto;
  display: flex;
  justify-content: flex-end;
}

.ai-offer-writer__container {
  background-color: #22242a;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
}

/* ========== OFFER WRITER HEADER STYLES ========== */
.ai-offer-writer__header {
  padding: 18px 20px;
  display: flex;
  gap: 10px;
}

.ai-offer-writer__sparkle-icon {
  width: 22px;
}

/* ========== OFFER WRITER CONTENT AREA STYLES ========== */
.ai-offer-writer__content {
  display: flex;
  overflow-y: scroll;
  flex-direction: row;
  min-height: calc(100% - 21px - 36px);
  margin-right: 10px;
}

.ai-offer-writer__content-left {
  width: fit-content;
  overflow-y: scroll;
  height: 100%;
  position: relative;
  min-width: 400px;
}

.ai-offer-writer__content-right {
  overflow-y: scroll;
  max-height: 100%;
  border-top-left-radius: 7.5px;
  border-top-right-radius: 7.5px;
  margin-right: 10px;
  background-color: white;
}

/* ========== PDF PAGE ARROWS ========== */
.ai-offer-writer__pdf-page-nav {
  position: absolute;
  top: 52.5%;
  background-color: rgb(54, 84, 255, 0.6);
  color: white;
  font-weight: 700;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s;
}

.ai-offer-writer__pdf-page-nav:hover {
  background-color: rgba(255, 255, 255);
  color: rgb(54, 84, 255, 1);
}

/* ========== OFFER WRITER SECTION ========== */
.ai-offer-writer__section {
  width: fit-content;
  min-width: 400px;
  padding: 20px;
}

.ai-offer-writer__section-name {
  font-weight: 300;
  margin-bottom: 8px;
  font-size: 16px;
}

.ai-offer-writer__section-fields {
  padding: 4px 10px;
  border-radius: 7.5px;
  background-color: rgb(54, 84, 255, 0.1);
}

.ai-offer-writer__section-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 8px 2px;
}

.ai-offer-writer__section-field-label {
  font-weight: 200;
  color: rgb(125, 144, 255, 0.4);
  font-size: 14px;
}

.ai-offer-writer__section-field-value {
  all: unset;
  font-size: 14px;
  font-weight: 400;
  color: white;
  text-align: right;
}
.ai-offer-writer__section-field-value:disabled {
  color: rgb(125, 144, 255, 0.25);
}
.ai-offer-writer__section-field-value::placeholder {
  font-style: italic;
  font-weight: 300;
}

/* ========== REACT-PDF STYLES ========== */
.react-pdf__Document {
  max-height: 100%;
}

.react-pdf__Page__annotations {
  display: none;
}

/* ========== AI EMAIL VIEWER STYLES ========== */
.ai-email-viewer {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  overflow-y: auto;
  display: flex;
  justify-content: flex-end;
}

.ai-email-viewer-container {
  background-color: #22242a;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 40%;
  display: flex;
  flex-direction: column;
}

/* ========== EMAIL VIEWER HEADER STYLES ========== */
.ai-email-viewer__header {
  padding: 18px 30px;
  display: flex;
  gap: 10px;
}

.ai-email-viewer__sparkle-icon {
  width: 22px;
}

.ai-email-viewer__title {
  display: flex;
  align-items: center;
}

.ai-email-viewer__exit {
  font-size: 30px;
  font-weight: 300;
  margin-left: auto;
  cursor: pointer;
}

/* ========== EMAIL  STYLES ========== */
.ai-email-viewer__email {
  margin: 0 20px;
  border-radius: 5px;
  padding: 15px 20px;
  background-color: #383838;
  font-weight: bold;
}

/* Email Header styles */
.ai-email-viewer__email-header {
  display: flex;
  flex-direction: column;
}

.ai-email-viewer__recipient,
.ai-email-viewer__sender {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.ai-email-viewer__recipient p,
.ai-email-viewer__sender p {
  margin: 0;
}

.ai-email-viewer__recipient-prefix,
.ai-email-viewer__sender-prefix {
  width: 60px;
}

.ai-email-viewer__recipient-email,
.ai-email-viewer__sender-email {
  margin-left: auto;
}

/* Subject styles */
.ai-email-viewer__email-subject {
  margin-top: 10px;
  border-top: 2px solid white;
  display: flex;
  gap: 10px;

}

.ai-email-viewer__subject-prefix {
    width: 60px;
}

.ai-email-viewer__subject-prefix, 
.ai-email-viewer__subject-title {
    margin: 14px 0;
}
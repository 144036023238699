.price-determination__options {
  display: flex;
  gap: 30px;
  margin-top: 25px;
}

.price-determination__box {
  width: 120px;
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: bold;
  position: relative;
  cursor: pointer;
  margin-top: -5px;
}

.price-determination__box--regular {
  background-color: white;
  color: #3654ff;
  border: 2px solid #3654ff;
}

.price-determination__box--suggested {
  background-color: #3654ff;
  color: white;
  border: 2px solid #3654ff;
}

.price-determination__value {
  font-size: 18px;
  margin-top: 42px;
}

.price-determination__tooltip {
  position: relative;
}

.price-determination__info-icon {
  background-color: #3654ff;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  margin-top: 8px;
}

.price-determination__tooltip-text {
  visibility: hidden;
  background-color: white;
  color: #3654ff;
  border: 1px solid #3654ff;
  text-align: center;
  padding: 6px;
  border-radius: 4px;
  position: absolute;
  bottom: 25px;
  width: 500px;
  margin-bottom: 75px;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 5;
  font-weight: 400;
}

.price-determination__tooltip:hover .price-determination__tooltip-text {
  visibility: visible;
  opacity: 1;
}

.price-determination__label {
  font-size: 14px;
  color: grey;
  font-weight: 400;
  text-align: center;
}

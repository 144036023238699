:root {
  --dashboard-primary-color: #3654ff;
  --dashboard-secondary-color: #647cff;
  --dashboard-tertiary-color: #c0c0c0;
  --dashboard-complete-color: #2aa917;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Outfit", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: #000000;
  margin: 0;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Make all standard css scrollbars invisible */
::-webkit-scrollbar {
  background: transparent;
  width: 0px;
}

/* animation controllers */

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes cursor-fade {
  0% {
    border-right: 2px solid var(--dashboard-primary-color);
  }
  100% {
    border-right: transparent;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

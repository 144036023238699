.dashboard {
  margin: 50px 32px;
}

/* ========== DASHBOARD HEADER ========== */

.dashboard__header {
  display: flex;
  flex-direction: row;
}

/* Header Left-side (date/message) */
.dashboard__header-left-date {
  font-size: 20px;
  color: #323232;
  font-weight: 350;
}
.dashboard__header-left-message {
  font-size: 40px;
  font-weight: 500;
}

.dashboard__header-right {
  align-content: center;
  margin-left: auto;
}

/* Header Right-side (home search) */
.dashboard__header-right-search {
  border: 1.5px solid #e0e0e0;
  padding: 9px 17px;
  border-radius: 22.5px;
  display: flex;
  flex-direction: row;
}
.dashboard__header-right-search-kai-photo {
  width: 28px;
}
.dashboard__header-right-search-input {
  font-weight: 400;
  font-size: 15px;
  width: 500px;
  padding: 5px;
  border: none;
  font-family: "Outfit", sans-serif;
  margin-left: 7px;
}
.dashboard__header-right-search-input:focus {
  border: none;
  outline: none;
}
.dashboard__header-right-search-input::placeholder {
  color: #787878;
  font-weight: 300;
}
.dashboard__header-right-search-magnifying-glass {
  width: 20px;
}

/* ========== DASHBOARD BODY ========== */

.dashboard__body {
  display: flex;
  flex-direction: row;
  margin-top: 26px;
  gap: 40px;
}

.dashboard__body-left {
  width: 400px;
}
.dashboard__body-right {
  flex: 1;
  border: 1.8px solid #ebebeb;
  border-radius: 16px;
  padding: 28px;
  height: fit-content;
}

/* ========== HOME PREVIEW ========== */

.dashboard__body-left-home-preview {
  border: 1.8px solid #ebebeb;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.dashboard__body-left-home-preview-photo {
  width: 400px;
  height: 225px;
  object-fit: cover;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}
.dashboard__body-left-home-preview-photo-loading {
  width: 400px;
  height: 225px;
  background: #f6f7f8;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}
.dashboard__body-left-home-preview-photo-loading::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(143, 143, 143, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(194, 194, 194, 0.395) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer-img 1s infinite;
}
.dashboard__body-left-home-preview-info {
  padding: 15px 14px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.dashboard__body-left-home-preview-info-home-name {
  -webkit-appearance: none; /* Remove default arrow in WebKit browsers */
  -moz-appearance: none; /* Remove default arrow in Firefox */
  appearance: none; /* Remove default arrow in modern browsers */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"); /* Custom arrow */
  background-repeat: no-repeat;
  background-position: right 8px center; /* Adjust position as needed */
  background-size: 12px; /* Adjust size as needed */
  border: none;
  outline: none;

  font-size: 22px;
  font-weight: 600;
  color: #3654ff;
  font-family: "Outfit";
}
.dashboard__body-left-home-preview-info-list-price {
  font-weight: 300;
  margin-top: 5px;
  font-size: 16px;
}

/* ========== FEED ========== */

/* Feed Header */
.dashboard__body-left-feed {
  margin-top: 35px;
}
.dashboard__body-left-feed-header {
  display: flex;
  flex-direction: row;
}
.dashboard__body-left-feed-header-left-title {
  font-size: 26px;
  font-weight: 550;
}
.dashboard__body-left-feed-header-left-subtitle {
  font-size: 14px;
  font-weight: 400;
  color: rgb(154, 154, 154);
}
.dashboard__body-left-feed-header-right {
  align-content: center;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dashboard__body-left-feed-header-right-text {
  margin-right: 9px;
  color: #323232;
  font-size: 18px;
}
.dashboard__body-left-feed-header-right-icon {
  width: 20px;
}

/* Feed Content */
.dashboard__body-left-feed-body {
  margin-top: 4px;
  border: 1.8px solid #ebebeb;
  border-radius: 16px;
  min-height: 180px;
  color: #323232;
  align-content: center;
  text-align: center;
}

/* ========== MY TEAM ========== */

/* My Team Header */
.dashboard__body-left-myteam {
  margin-top: 35px;
}
.dashboard__body-left-myteam-header {
  display: flex;
  flex-direction: row;
}
.dashboard__body-left-myteam-header-left-title {
  font-size: 26px;
  font-weight: 550;
}
.dashboard__body-left-myteam-header-left-subtitle {
  font-size: 14px;
  font-weight: 400;
  color: rgb(154, 154, 154);
}
.dashboard__body-left-myteam-header-right {
  align-content: center;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.dashboard__body-left-myteam-header-right-text {
  margin-right: 9px;
  color: #323232;
  font-size: 18px;
}
.dashboard__body-left-myteam-header-right-icon {
  font-weight: 600;
  color: #323232;
}

/* My Team Content */
.dashboard__body-left-myteam-body {
  margin-top: 4px;
  border: 1.8px solid #ebebeb;
  border-radius: 16px;
  min-height: 180px;
  padding: 12px;
}

/* My Team Contact */
.dashboard__body-left-myteam-body-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
}
.dashboard__body-left-myteam-body-contact-photo {
  width: 54px;
}
.dashboard__body-left-myteam-body-contact-info {
  display: flex;
  flex-direction: column;
  margin-left: 18px;
}
.dashboard__body-left-myteam-body-contact-info-name {
  font-size: 20px;
  font-weight: 550;
}
.dashboard__body-left-myteam-body-contact-info-role {
  font-size: 13px;
  color: #808080;
  margin-bottom: 2px;
}
.dashboard__body-left-myteam-body-contact-info-contact {
  font-size: 11px;
}

/* My Team Contact - Kai AI specifics */
.dashboard__body-left-myteam-body-contact-kai-info {
  font-size: 20px;
  font-weight: 550;
  align-content: center;
  margin-left: 18px;
}
.dashboard__body-left-myteam-body-contact-kai-chat {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-left: auto;
  padding: 6px 14px;
  color: white;
  font-weight: 500;
  background-color: #3654ff;
  border-radius: 14px;
  cursor: pointer;
}

/* ========== DOCUMENTS ========== */
.dashboard__body-left-documents {
  margin-top: 35px;
  margin-bottom: 4px;
}
.dashboard__body-left-documents-header {
  font-size: 26px;
  font-weight: 550;
}
.dashboard__body-left-documents-body {
  margin-top: 6px;
  border: 1.8px solid #ebebeb;
  border-radius: 16px;
  padding: 12px;
}
.dashboard__body-left-documents-body-document {
  padding: 12px;
  display: flex;
  align-content: center;
}
.dashboard__body-left-documents-body-document-pdf-icon {
  margin-right: 9px;
}

/* ========== DASHBOARD RIGHT HEADER  ========== */
.dashboard__body-right-header-property-name {
  -webkit-appearance: none; /* Remove default arrow in WebKit browsers */
  -moz-appearance: none; /* Remove default arrow in Firefox */
  appearance: none; /* Remove default arrow in modern browsers */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"); /* Custom arrow */
  background-repeat: no-repeat;
  background-position: right 8px center; /* Adjust position as needed */
  background-size: 12px; /* Adjust size as needed */
  border: none;
  outline: none;

  width: 100%;
  margin-bottom: 6px;

  font-size: 42px;
  color: #3654ff;
  font-weight: 600;
  font-family: "Outfit";
}

.dashboard__body-right-header-status {
  font-weight: 500;
  font-size: 16px;
  margin-top: 14px;
}

/* ========== DASHBOARD STATUS STEP  ========== */
.dashboard__body-right-step-section {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dashboard__body-right-step {
  background-color: #f8f8f8;
  font-weight: 500;
  font-size: 22px;
  padding: 12px 0px;
}
.dashboard__body-right-step.complete {
  border-left: 5px solid #28ab40;
}
.dashboard__body-right-step.inprogress {
  border-left: 5px solid #f8b967;
}
.dashboard__body-right-step.future {
  border-left: 5px solid #939393;
}
.dashboard__body-right-step-name {
  padding: 12px;
}
.dashboard__body-right-step-substeps {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.dashboard__body-right-step-substep:first-child {
  margin-top: 20px;
}
.dashboard__body-right-step-substep {
  font-size: 16px;
  font-weight: 350;
  margin-top: 10px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dashboard__body-right-step-substep.complete {
  background-color: #e6f7e6;
}

.dashboard__body-right-step-substep.inprogress {
  background-color: #fde3c1;
}

.dashboard__body-right-step-continue-button {
  border: none;
  width: fit-content;
  cursor: pointer;
  font-family: "Outfit", sans-serif;
}

.dashboard-component-header {
  display: flex; 
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

/* .dashboard-component-header::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px; 
    background-color: black; 
} */

.dashboard-component-header__title,
.dashboard-component-header__address {
  margin: 0;
}

.dashboard-component-header__title {
  font-size: 54px;
  font-weight: 500;
}

.dashboard-component-header__address {
  font-size: 18px;
  font-weight: 300;
  margin-top: 15px;
  margin-right: 40px;
}

/* ========== AI CHAT VIEWER STYLES ========== */
.ai-disclosure-analysis {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  height: 100%s;
}

.ai-disclosure-analysis__container {
  background-color: #22242a;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 90%;
  display: flex;
  flex-direction: column;
}

/* ========== CHAT HEADER STYLES ========== */
.ai-disclosure-analysis__header {
  padding: 18px 30px;
  display: flex;
  gap: 10px;
}

.ai-disclosure-analysis__title {
  display: flex;
  align-items: center;
}

.ai-disclosure-analysis__sparkle-icon {
  width: 22px;
}

.ai-disclosure-analysis__exit {
  font-size: 30px;
  font-weight: 300;
  margin-left: auto;
  cursor: pointer;
}

/* ========== CONTENT STYLES ========== */
.ai-disclosure-analysis__content {
  display: flex;
  padding: 0 30px;
  gap: 16px;
}

.ai-disclosure-analysis__analysis-container {
  width: 45%;
  position: relative;
}

.ai-disclosure-analysis__pdf-container {
  width: 50%;
}

/* ========== ANALYSIS STYLES ========== */
.disclosure-package__analysis-results {
  position: absolute;
  max-height: 600px;
  overflow-y: scroll;
  box-sizing: border-box;
}

/* Dropdown styles */
.disclosure-package__dropdown {
  border-radius: 5px;
  background-color: #24293f;
  margin-bottom: 11px;
  padding: 10px;
  font-size: 12px;
  font-weight: 600;
}

/* Dropdown button styles */
.disclosure-package__dropdown-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.disclosure-package__dropdown-title {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
}

/* Arrow style */
.disclosure-package__dropdown-arrow {
  width: 12px;
  height: 12px;
  transition: transform 0.3s ease;
}

.disclosure-package__dropdown-arrow--open {
  transform: rotate(180deg);
}

.disclosure-package__dropdown-arrow {
  width: 16px;
  height: 16px;
  transition: transform 0.3s ease;
}

/* Content styles */
.disclosure-package__dropdown-content {
  padding: 10px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 8px;
}

.disclosure-package__analysis-item h4 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  color: #ffffff;
}

/* Markdown styles */
.markdown-content {
  height: fit-content;
  padding-right: 10px;
  font-size: 13px;
  line-height: 1.5;
  overflow-x: scroll;
}

.markdown-content table {
  border-collapse: collapse;
}

.markdown-content th,
.markdown-content td {
  border: 1px solid #e0e0e0;
  padding: 8px 12px;
  text-align: left;
  font-weight: normal;
}

.markdown-content th {
  font-weight: bolder;
}

.markdown-content h2,
.markdown-content h3,
.markdown-content h4 {
  margin-top: 24rem;
  margin-bottom: 12px;
}

.markdown-content p {
  margin: 8px 0;
}

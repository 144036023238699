/* ========== AI CHAT VIEWER STYLES ========== */
.ai-chat-viewer {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  overflow-y: auto;
  display: flex;
  justify-content: flex-end;
}

.ai-chat-viewer__container {
  background-color: #22242a;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 60%;
  display: flex;
  flex-direction: column;
}

/* ========== CHAT HEADER STYLES ========== */
.ai-chat-viewer__header {
  padding: 18px 30px;
  display: flex;
  gap: 10px;
}

.ai-chat-viewer__title {
  display: flex;
  align-items: center;
}

.ai-chat-viewer__sparkle-icon {
  width: 22px;
}

.ai-chat-viewer__exit {
  font-size: 30px;
  font-weight: 300;
  margin-left: auto;
  cursor: pointer;
}

/* ========== CONTENT AREA STYLES ========== */
.ai-chat-viewer__content {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 30px;
}

/* ========== MESSAGEING STYLES ========== */
.ai-chat-viewer__message {
  padding: 13px 6px;
  border-radius: 10px;
  max-width: 80%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: flex;
  font-size: 13px;
  flex-direction: column;
}

.ai-chat-viewer__message p {
  margin: 0;
}

.ai-chat-viewer__message code {
  white-space: pre-wrap;
}

/* User Message styles */
.ai-chat-viewer__message.user {
  background-color: #353841;
  color: white;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: auto;
}

/* Assistant message style */
.ai-chat-viewer__message.assistant {
  align-self: flex-start;
  justify-content: flex-start;
  margin-right: auto;
}

.ai-chat-viewer__message.assistant.loading {
  font-style: italic;
  color: gray;
}

.ai-chat-viewer__message-icon-container {
  margin-bottom: 10px;
}

.ai-chat-viewer__message-icon {
  width: 30px;
}

/* Loading style */
.ai-chat-viewer__loading-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.ai-chat-viewer__dot {
  width: 8px;
  height: 8px;
  background-color: #ccc;
  border-radius: 50%;
  animation: dot-bounce 1.5s infinite ease-in-out;
}

.ai-chat-viewer__loading-dots span:nth-child(1) {
  animation-delay: 0s;
}

.ai-chat-viewer__loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.ai-chat-viewer__loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-bounce {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

/* ========== PROMPT STYLES ========== */
.ai-chat-viewer__prompt-form {
  display: flex;
  margin-top: auto;
  padding: 30px 25px;
  border-top: 1px solid #444;
}

.ai-chat-viewer__prompt-input {
  width: 100%;
  background-color: #353841;
  border: none;
  padding: 14px 14px;
  border-radius: 10px;
  font-size: 13px;
  font-family: "Outfit", sans-serif;
  font-display: swap;
  color: white;
}

.ai-chat-viewer__prompt-input::placeholder {
  color: #868686;
}

.ai-chat-viewer__prompt-input:focus {
  outline: none;
}

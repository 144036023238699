.signup_container {
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
  padding-top: 100px;
  flex-direction: column;
}

.signup_agnsy_logo {
  margin-bottom: 20px;
  width: 140px;
}

.signup_formWrapper {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.signup_heading {
font-family: "Outfit", sans-serif;
  font-display: swap;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 5px;
}

.signup_subtitle {
font-family: "Outfit", sans-serif;
  font-display: swap;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 40px;
}

.signup_inputGroup {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.signup_inputField {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px 15px;
  width: 100%;
font-family: "Outfit", sans-serif;
  font-display: swap;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.signup_inputField input.PhoneInputInput {
  border: None;
}
.signup_inputField input.PhoneInputInput:focus {
  outline: none;
}

.signup_inputLabel {
font-family: "Outfit", sans-serif;
  font-display: swap;
  font-size: 13px;
  text-align: left;
}

.signup_button {
  background-color: #3654ff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 12px 0;
  width: 100%;
  cursor: pointer;
font-family: "Outfit", sans-serif;
  font-display: swap;
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
}
.signup_button:disabled {
  background-color: grey;
  color: rgb(206, 206, 206);
}

.signup_alert {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  min-height: 20px; /* Maintain consistent height */
  visibility: hidden; /* Default to hidden */
}

.signup_alert--visible {
  visibility: visible; /* Show only when there's an error */
}

.signup_already_user {
font-family: "Outfit", sans-serif;
  font-display: swap;
}

.signup_home_icon {
  width: 45px;
  height: 45px;
}

/* ========== EMAIL PREVIEW STYLES ========== */
.email-preview {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.email-preview__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2d2d39;
  padding: 12px 16px;
  color: white;
}

.email-preview__icon {
  display: flex;
  align-items: center;
  gap: 8px;
}

.email-preview__icon img {
  width: 20px;
  height: 20px;
}

/* Customize button overlay */
.email-preview__customize-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.email-preview__customize-btn {
  background-color: #3366ff;
  display: flex;
  gap: 5px;
  align-items: center;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s;
  z-index: 1;
  box-shadow: 0 4px 12px rgba(51, 102, 255, 0.3);
}

.email-preview__customize-btn:hover {
  background-color: #2a52cc;
  transform: scale(1.05);
}

.email-preview__content {
  padding: 20px;
  flex-grow: 1;
  background-color: rgb(247, 247, 247);
  border: 1px solid grey;
  border-radius: 14px;
  transition: filter 0.3s ease;
}

.email-preview__content--blurred {
  filter: blur(3px);
}

.email-preview__field {
  display: flex;
  margin-bottom: 12px;
}

.email-preview__label {
  width: 80px;
  font-weight: 500;
  color: #555;
}

.email-preview__value {
  flex-grow: 1;
}

.email-preview__divider {
  height: 1px;
  background-color: #e0e0e0;
  margin: 16px 0;
}

.email-preview__body {
  margin-top: 20px;
  line-height: 1.5;
}

.email-preview__signature {
  margin-top: 20px;
}

.email-preview__send-btn {
  background-color: white;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 50px;
  padding: 8px 24px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.email-preview__send-btn:hover {
  background-color: #f5f5f5;
}

/* ========== GENERAL OVERVIEW COMPONENT STYLES ========== */
.overview {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.overview__description {
    font-size: 23px;
    font-weight: medium;
}

/* ========== STEPS SECTION STYLES ========== */
.overview__steps {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
}

.overview__step-item {
  margin-bottom: 5px;
  font-size: 19px;
}

.overview__step-name {
  color: var(--dashboard-primary-color);
  text-decoration: underline;
  font-size: 20px;
}

/* ========== CHAT SUGGESTIONS STYLES ========== */
.overview__chats {
  margin-top: 25px;
}

.overview__chat-suggestions {
  display: flex;
  gap: 8px;
}

.overview__chat,
.overview__chat-suggestion {
  background-color: #f8f9ff;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.overview__chat {
  height: 30px;
  width: 30px;
  justify-content: center;
}

.overview__chat-icon,
.overview__chat-suggestion-icon {
  width: 15px;
  height: 15px;
}

.overview__chat-suggestion {
  gap: 10px;
  width: auto;
  height: 30px;
  padding: 0 10px;
  font-size: 13px;
  font-weight: 400;
}

/* ========== CTA BUTTON STYLES ========== */
.overview__cta {
  margin-top: 65px;
}

.overview__cta-button {
  width: 80px;
  background-color: var(--dashboard-primary-color);
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 11px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.overview__cta-button:hover {
  opacity: 0.8;
}
/* ========== GENERAL CASH COMPONENT STYLES ========== */
.enter-cash {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.enter-cash__description {
  margin: 0;
  margin-bottom: 20px;
}

/* ========== INPUT SECTION STYLES ========== */
.enter-cash__input-container {
  display: flex;
  align-items: center;
  /* border: 2px solid var(--dashboard-primary-color);
  border-radius: 5px;
  padding: 0.5rem; */
}

.enter-cash__input-icon {
  color: var(--dashboard-primary-color);
  font-size: 22px;
  margin-right: 10px;
}

.enter-cash__input {
  border: 2px solid var(--dashboard-primary-color);
  padding: 11px 10px;
  border-radius: 10px;
  width: 320px;
  font-size: 16px;
}

.enter-cash__input::placeholder {
    color: #D2D2D2;
}

/* ========== CTA BUTTON STYLES ========== */
.enter-cash__cta {
  margin-top: 60px;
}

.enter-cash__cta-button {
  background-color: #CBCBCC;
  color: white;
  border: none;
  font-size: 14px;
  padding: 12px 11px;
  border-radius: 10px;

}

.enter-cash__cta-button:not(:disabled) {
  background-color: var(--dashboard-primary-color);
  cursor: pointer;
}

.enter-cash__cta-button:not(:disabled):hover {
  opacity: 0.8;
}

/* ========== HELP TEXT STYLES ========== */
.enter-cash__help {
  margin-top: 10px;
}

.enter-cash__help-text {
  color: #CBCBCC;
  margin: 0;
  text-decoration: underline;
  font-size: 11px;
  cursor: pointer;
}


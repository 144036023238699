.budgeting {
  margin-top: 40px;
  color: var(--dashboard-primary-color);
  font-size: 17px;
  font-weight: 500;
}

.budgeting__header {
  display: flex;
  align-items: center;
  gap: 15px;
}

.budgeting__avatar {
  width: 60px;
  height: auto;
}

.budgeting__chat-icon-container {
  padding: 7px;
  border-radius: 10px;
  background-color: #f8f9ff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  cursor: pointer;
}

.budgeting__chat-icon {
  width: 15px;
  height: auto;
}

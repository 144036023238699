.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.loading-spinner-text {
  margin-top: 20px;
  font-size: 20px;
}

.loading-spinner__outer {
  height: 100px;
  width: 100px;
  background: conic-gradient(white 0%, black 100%);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  mask: radial-gradient(closest-side, transparent 85%, rgba(0, 0, 0, 1) 70%);
}

.buyingflow {
  display: flex;
  /* flex-direction: row; */
  width: 100%;
  height: fit-content; /* Subtract nav height */
  min-height: calc(100vh - 70px);
}

.buyingflow-content {
  flex-grow: 1;
  transition: margin-left 0.5 ease-in-out;
  padding: 40px;
  position: relative;
}

.buyingflow-nav {
  background-color: #171717;
  color: white;
  height: auto;
  transition: width 0.5s ease-in-out;
  padding: 40px 15px;
  position: relative;
}

.buyingflow-nav--expanded {
  width: 290px;
}

.buyingflow-nav--contracted {
  width: 60px;
}

/* Button styles */
.buyingflow-nav-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 10px 0;
  padding: 15px;
  border-radius: 5px;
  height: 60px;
  transition: all 0.3s ease-in-out;
  position: relative;
  border: 2px solid transparent;
  margin-left: 50px;
}

.buyingflow-nav-btn:hover {
  background-color: color-mix(
    in srgb,
    var(--dashboard-primary-color) 10%,
    transparent
  );
}

/* Text container positioning and transitions */
.buyingflow-nav-btn__text-container {
  overflow: hidden;
  white-space: nowrap;
  transition: max-width 1.7s ease-in-out, opacity 1.7s ease-in-out;
  max-width: 160px;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 15px;
}

/* Expanded state styles */
.buyingflow-nav--expanded .buyingflow-nav-btn__text-container {
  display: block;
  max-width: 160px;
  opacity: 1;
}

.buyingflow-nav--expanded .buyingflow-nav-btn {
  justify-content: flex-end;
}

.buyingflow-nav--expanded .buyingflow-nav-btn--current {
  border: 2px solid var(--dashboard-primary-color);
  background-color: color-mix(
    in srgb,
    var(--dashboard-primary-color) 30%,
    transparent
  );
}

/* Contracted state styles */
.buyingflow-nav--contracted .buyingflow-nav {
  display: flex;
  justify-content: center;
}
.buyingflow-nav--contracted .buyingflow-nav-btn {
  margin-left: 0;
  padding: 15px 0;
}

.buyingflow-nav--contracted .buyingflow-nav-btn__text-container {
  max-width: 0;
  opacity: 0;
  white-space: nowrap;
  padding: 0;
  display: none;
}

.buyingflow-nav--contracted .buyingflow-nav-btn__icon-container {
  justify-content: center;
  width: 100%;
}

.buyingflow-nav--contracted .buyingflow-nav-btn__icon {
  width: 34px;
}

.buyingflow-nav--contracted .buyingflow-nav-btn--current::before {
  content: "";
  position: absolute;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
  width: 7px;
  height: 36px;
  background-color: var(--dashboard-primary-color);
  border-radius: 4px;
  transition: all 1.2s ease-in-out;
}

/* Text styles */
.buyingflow-nav-btn__step,
.buyingflow-nav-btn__title {
  margin: 0;
  transition: opacity 0.3s ease-in-out;
  white-space: nowrap;
}

/* Completed state styles */
.buyingflow-nav-btn--completed .buyingflow-nav-btn__step,
.buyingflow-nav-btn--completed .buyingflow-nav-btn__title {
  color: var(--dashboard-complete-color);
}

/* Icon styles */
.buyingflow-nav-btn__icon-container {
  display: flex;
  align-items: center;
  transition: width 0.8s ease-in-out;
}

.buyingflow-nav-btn__icon {
  width: 43px;
  height: auto;
  transition: width 0.3s ease-in-out;
}
